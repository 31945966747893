function alert({ message, type}){
    if(!type) {
        type = 'warning'
    }

    return (
        <>
            {
                message ? <div className={"alert alert-warning-custom " + "alert-"+ type} role="alert">
                    {message}
                </div> : ''
            }
        </>
    )
}

export default alert;