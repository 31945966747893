import Header from "../components/header";
import List from "../components/list";

function Home() {
    const userLogged = localStorage.getItem('USER_INFO') ? JSON.parse(localStorage.getItem('USER_INFO')) : '';

    return (
       <div className="Home">
           <Header userLogged={userLogged} />
           <List />
       </div>
    )
} 

export default Home;