import Header from "../components/header";
import './share.css'
import API from '../api/index';
import { useState, useEffect } from "react";
import Alert from '../components/alert';

function Share() {
    const userLogged = localStorage.getItem('USER_INFO') ? JSON.parse(localStorage.getItem('USER_INFO')) : '';

    const [url, setUrl] = useState('');
    const [message, setMessage] = useState('');
    const [type, setType] = useState('');

    function onChangeUrl(e){
        setUrl(e.target.value);
    }

    function validateUrlYoutube(url){
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length == 11) {
            return match[2];
        } else {
            return false;
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setMessage('');
        }, 3000);
    }, [message])

    async function shareVideo(e){
        e.preventDefault();

        const isValidateURl = validateUrlYoutube(url);
        if(isValidateURl) {
            const result = await API.shareVideo(url);

            if(result?.error) {
                setMessage(result?.error?.message);
                setUrl('');
            } else {
                setType('success');
                setMessage('Thêm video thành công !');
                setUrl('');
            }
            
        } else {
            setType('warning');
            setMessage('Youtube Url không hợp lệ !');
        }
    }

    return (
        <div className="share-page">
            <Alert message={message} type={type}/>
            <Header userLogged={userLogged} />
            <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="share-box">
                            <div className="text-box">Share a video movie</div>

                            <form className="share-form">
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label">Youtube URL</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" onChange={(e) => onChangeUrl(e)} placeholder='https://www.youtube.com/watch?v=dPrg3uuG1yk' />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label"></label>
                                    <div className="col-md-9">
                                        <button className="btn btn-primary btn-share-page" onClick={(e) => shareVideo(e)} >Share</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                </div>
            </div>
        </div>
    )
} 

export default Share;