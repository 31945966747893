import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './containers/Home';
import Share from './containers/Share';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className='container'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/share" element={
            <ProtectedRoute>
              <Share />
            </ProtectedRoute>
          } />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  </React.StrictMode>
);

function ProtectedRoute({ children }){
  let auth = localStorage.getItem('USER_INFO');
  if (!auth) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function NotFound(){
  return (
    <div>Component notfound, Please go to Home</div>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
