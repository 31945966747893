import './list.css';
import { useEffect, useState } from 'react';
import API from '../api/index';
import Loading from '../components/loading';
import VideoYoutube from './videoYoutube';


function List() {
    const [videos, setVideos] = useState();

    useEffect(() => {
        API.getVideos()
        .then(_videos => {
            setVideos(_videos?.data);
        })
        .catch(err => console.log(err))
    }, []);

    return (
        <div className='video-list'>
            {
                !videos ? <div className='center-x'><Loading /></div> : 

                videos?.map(video => {
                    return <VideoYoutube key={video.id} info={video}/>
                })
            }
        </div>
    )
}

export default List;