import axios from 'axios';


function handleErrorResponse(err){
    if (err.response?.data?.error) {
        return err.response?.data;
    } else {
        return {error: { message: err.message }}
    }
}

async function login(email, password) {
    return axios.post(process.env.REACT_APP_URL + '/users/loginOrRegister', {
        email,
        password
    }).then(res => res)
    .catch(err => handleErrorResponse(err))
}

async function shareVideo(url){
    const load_user = localStorage.getItem('USER_INFO');
    const token = load_user ? JSON.parse(load_user)?.token : '';

    return axios.post(process.env.REACT_APP_URL + '/videos/share', {
        url
    }, {
        headers: {
            Authorization: token
        }
    }).then(res => res)
    .catch(err => handleErrorResponse(err));
}

async function getVideos(){
    return axios.get(process.env.REACT_APP_URL + '/videos/').then(res => res)
    .catch(err => handleErrorResponse(err))
}

export default {
    login,
    shareVideo,
    getVideos
}

