import logo from '../assets/images/logo.png';
import './header.css';

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import Alert from '../components/alert';

import API from '../api/index';
const USER_INFO = 'USER_INFO';

function Header({ userLogged }) {
    const [user, setUser] = useState(userLogged || '');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [warning, setWarning] = useState('');
    const navigate  = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            setWarning('');
        }, 2000);
    }, [warning])

    async function login(e){
        e.preventDefault();
        const emailValid = validateEmail(email);

        if(!emailValid) return;

        const result = await API.login(email, password);

        if(result?.error) {
            setWarning(result?.error?.message);
        } else {
            const user = {
                data: result?.data.data,
                token: result?.data.token
            }
            localStorage.setItem(USER_INFO,  JSON.stringify(user));
            setUser(user);
            navigate('/');
        }
    }

    function validateEmail(mail){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return (true)
        }
            setWarning('Định dạng email chưa đúng !!!');
            return (false)
    }

    function logout(){
        setUser('');
        setEmail('');
        setPassword('');
        localStorage.removeItem(USER_INFO);
        navigate('/');
    }

    function onChangeEmail(e){
        setEmail(e.target.value);
    }

    function onChangePassword(e){
        setPassword(e.target.value);
    }

    function renderLogin(){
        return (
            <form data-testid="login-form" className="form-inline d-flex form-center">
                <input type="text" className="form-control input-email" name="email" placeholder='email' onChange={(e) => onChangeEmail(e)} value={email} />
                <input type="password" className="form-control input-password" placeholder='passowrd' onChange={(e) => onChangePassword(e)} value={password} />
                <button type="submit" className="btn btn-primary btn-register-login" onClick={(e) => login(e)} >Register / Login</button>
            </form>
        )
    }

    function renderLoggedIn() {
        return (
            <div data-testid="logged" className="logged">
                <span className='email'>Welcome : {user.data.email}</span>

                <Link className='btn btn-primary btn-share' to="/share">Share a movie</Link>
                <button className='btn btn-danger btn-logout' onClick={() => logout()} >Logout</button> 
            </div>
        )
    }

    return (
        <header className="app-header row">
            <Alert message={warning} type="warning"/>
            <div className="logo col-md-5">
                <Link className='link-text' to="/">
                    <img src={logo} className="logo-image" alt="logo-image" />
                    <span className="logo-text" alt="logo-text">Funny Movies</span>
                </Link>
            </div>
            <div className="info-user col-md-7">
                {user.token ? renderLoggedIn() : renderLogin()}
            </div>
            <div className='divider'></div>
        </header>

    )
}

export default Header;