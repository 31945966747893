import './videoYoutube.css';
import imageLike from '../assets/images/like.png';

function YoutubeEmbed({ id }){
    return (
        <div className='col-md-6'>
            <div className="video-responsive">
                <iframe
                    width="640"
                    height="360"
                    src={`https://www.youtube.com/embed/${id}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
        </div>
    );
}

function VideoYoutube({info}) {
    const { id, title, like, dislike, description, shareBy } = info;
    return (
        <div data-testid={id} className='video-youtube row'>
            <YoutubeEmbed id={id} />
            
            <div className='content-text col-md-6'>
                <h3 className='title-video'>{title}</h3>
                <p>Share by: {shareBy}</p>
                <p>
                    {like} <img src={imageLike} className="like" alt="like button" /> {dislike} <img src={imageLike} className="dislike" /> 
                </p>
                <p>Description</p>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default VideoYoutube;